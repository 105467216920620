import Link from 'next/link';
import React, { useState } from 'react';

import { cx } from '@emotion/css';
import { CONTACT, HOME_ROUTES } from '@util/constants';
import { useWindowScrollPositions } from '@util/useWindowScrollPositions';

import styles from './HeaderView.module.scss';

export const HeaderView = () => {
  const { scrollY } = useWindowScrollPositions();

  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const onMobileMenuClick = () => {
    setOpenMobileMenu(!openMobileMenu);
  };

  return (
    <header
      className={cx(
        styles.header,
        scrollY >= 20 && styles.headerFixed,
        openMobileMenu && styles.menu_open,
      )}>
      <div className={styles.container}>
        <div
          className={cx(styles.header_wrap, scrollY >= 20 && styles.headerFix)}>
          <div className={styles.logo}>
            <Link href={HOME_ROUTES.HOME}>
              <img
                className={styles.style_logo_img}
                src="/images/header/qeeper-logo.svg"
                alt="Qeeper logo"
              />
            </Link>
          </div>
          <div
            className={cx(styles.menu, styles.for_desk, styles.for_desk_menu)}>
            <ul className={cx(styles.cstm_ul_head, styles.menu_ul)}>
              <li className={cx(styles.cstm_li, styles.menu_item)}>
                <Link
                  href={`tel:${CONTACT.PHONE}`}
                  target="_blank"
                  className={styles.menu_link}>
                  <i className={styles.phone_icon}>
                    {' '}
                    <img src="/images/header/phone.svg" />{' '}
                  </i>{' '}
                  {CONTACT.PHONE}
                </Link>
              </li>
              <li className={cx(styles.cstm_li, styles.top_saprate)}>
                <span className={styles.head_menu_seprater}>|</span>
              </li>
              <li className={cx(styles.cstm_li, styles.menu_item)}>
                <Link
                  href={`mailto:${CONTACT.EMAIL}`}
                  target="_blank"
                  className={styles.menu_link}>
                  <i className={styles.email_icon}>
                    {' '}
                    <img src="/images/header/email.svg" />{' '}
                  </i>
                  {CONTACT.EMAIL}
                </Link>
              </li>
            </ul>
          </div>
          <div className={styles.for_mob}>
            {/*<div className={styles.search_field}>
              <div>
                <span className={styles.search_btn}>
                  <img src="/images/header/search-btn.svg" />
                </span>
              </div>

              <div className={styles.search_modal}>
                <div className={styles.modal_inner_container}>
                  <div className={styles.search_close}>
                    <svg
                      className={styles.search_close_img}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 320 512">
                      <path d="M312 375a24 24 0 0 1-34 34L160 290 41 409a24 24 0 0 1-34-34l119-119L7 136a24 24 0 0 1 34-34l119 120 119-119a24 24 0 0 1 34 34L194 256l118 119z"></path>
                    </svg>
                  </div>
                  <div className={styles.search_form_wrapper}>
                    <form action="#">
                      <div className={styles.input_group}>
                        <input
                          type="text"
                          placeholder="Search"
                          name="property-address"
                          className={styles.search_modal_input}
                          required
                        />
                        <div className={styles.search_group}>
                          <button
                            type="submit"
                            className={cx(styles.search_submit, styles.button)}>
                            <img src="/images/header/search-btn.svg" />
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>*/}
            <div className={styles.menu_bars} onClick={onMobileMenuClick}>
              <span className={styles.menu_bars_span}></span>
              <span className={styles.menu_bars_span}></span>
              <span className={styles.menu_bars_span}></span>
            </div>
          </div>
        </div>

        <div
          className={cx(
            styles.header_menu_wrap,
            scrollY >= 20 && styles.headerMenuFix,
            openMobileMenu && styles.open,
          )}>
          <div
            className={cx(
              styles.header_menu,
              openMobileMenu && styles.open_active,
            )}>
            <ul className={styles.header_menu_ul}>
              <DesktopServiceLink
                styles={styles}
                linkHref={HOME_ROUTES.SERVICES.ELECTRICAL}
                title="Electrical"
              />
              <DesktopServiceLink
                styles={styles}
                linkHref={HOME_ROUTES.SERVICES.PLUMBING}
                title="Plumbing"
              />
              <DesktopServiceLink
                styles={styles}
                linkHref={HOME_ROUTES.SERVICES.HEAT_AND_GAS}
                title="Heat & Gas"
              />
              <DesktopServiceLink
                styles={styles}
                linkHref={HOME_ROUTES.SERVICES.HANDYMAN}
                title="Handyman"
              />
              <DesktopServiceLink
                styles={styles}
                linkHref={HOME_ROUTES.SERVICES.APPLIANCE_REPAIR}
                title="Appliance Repair"
              />

              <li
                className={cx(
                  styles.cstm_li,
                  styles.menu_item,
                  styles.has_submenu,
                )}>
                <Link
                  href="#"
                  className={cx(
                    styles.menus_link,
                    styles.menu_more,
                    styles.cstm_a,
                  )}>
                  {' '}
                  More{' '}
                </Link>
                <ul className={styles.sub_menu}>
                  <DesktopMoreServiceLink
                    styles={styles}
                    linkHref={HOME_ROUTES.SERVICES.LOCKSMITH}
                    title="Locksmith"
                  />

                  <DesktopMoreServiceLink
                    styles={styles}
                    linkHref={HOME_ROUTES.SERVICES.LANDLORD_CERTIFICATE}
                    title="Landlord Certificates"
                  />
                  <DesktopMoreServiceLink
                    styles={styles}
                    linkHref={HOME_ROUTES.SERVICES.FIRE_RISK_ASSESSMENT}
                    title="Fire Risk Assessment"
                  />

                  <DesktopMoreServiceLink
                    styles={styles}
                    linkHref={
                      HOME_ROUTES.SERVICES.CARPET_AND_UPHOLSTERY_CLEANING
                    }
                    title="Carpet & Upholstery Cleaning"
                  />
                  <DesktopMoreServiceLink
                    styles={styles}
                    linkHref={HOME_ROUTES.SERVICES.DRAINAGE}
                    title="Drainage"
                  />
                  <DesktopMoreServiceLink
                    styles={styles}
                    linkHref={HOME_ROUTES.SERVICES.WINDOW_DOOR}
                    title="Window & Door"
                  />
                </ul>
              </li>
              {/* <!--  ******************* only for mobile tab view ******************* --> */}
              <li
                className={cx(
                  styles.cstm_li,
                  styles.for_mobile,
                  styles.for_mob_user,
                )}>
                <div className={styles.quote_search}>
                  <div className={styles.quote_manage}>
                    <div className={cx(styles.menu, styles.for_mobile)}>
                      <ul className={cx(styles.cstm_ul_head, styles.mobile_ul)}>
                        <li className={cx(styles.cstm_li, styles.menu_item)}>
                          <Link
                            href={`tel:${CONTACT.PHONE}`}
                            target="_blank"
                            className={styles.menu_link}>
                            <i
                              className={cx(
                                styles.phone_icon,
                                styles.menu_link_i,
                              )}>
                              {' '}
                              <img src="/images/header/phone.svg" />
                            </i>{' '}
                            {CONTACT.PHONE}`
                          </Link>
                        </li>
                        <li className={cx(styles.cstm_li, styles.menu_item)}>
                          <Link
                            href={`mailto:${CONTACT.EMAIL}`}
                            target="_blank"
                            className={styles.menu_link}>
                            <i
                              className={cx(
                                styles.email_icon,
                                styles.menu_link_i,
                              )}>
                              {' '}
                              <img src="/images/header/email.svg" />{' '}
                            </i>
                            {CONTACT.EMAIL}
                          </Link>
                        </li>
                      </ul>
                    </div>
                    {/* <div className={styles.search_field}>
                      <div>
                        <span className={styles.search_btn}>
                          <img src="/images/header/search-btn.svg" />
                        </span>
                      </div>

                      <div className={styles.search_modal}>
                        <div className={styles.modal_inner_container}>
                          <div className={styles.search_close}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 320 512">
                              <path d="M312 375a24 24 0 0 1-34 34L160 290 41 409a24 24 0 0 1-34-34l119-119L7 136a24 24 0 0 1 34-34l119 120 119-119a24 24 0 0 1 34 34L194 256l118 119z"></path>
                            </svg>
                          </div>
                          <div className={styles.search_form_wrapper}>
                            <form action="#">
                              <div className={styles.input_group}>
                                <input
                                  type="text"
                                  placeholder="Search"
                                  name="property-address"
                                  className={styles.search_modal_input}
                                  required
                                />
                                <div className={styles.search_group}>
                                  <button
                                    type="submit"
                                    className={cx(
                                      styles.button,
                                      styles.search_submit,
                                    )}>
                                    <img src="/images/header/search-btn.svg" />
                                  </button>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div> */}
                    <ul className={styles.quote_links}>
                      <li className={cx(styles.cstm_li, styles.quote_links_li)}>
                        <Link
                          href={HOME_ROUTES.PROPERTY_MANAGER}
                          className={cx(
                            styles.property_manage,
                            styles.cstm_a,
                            styles.cmn_quote_links,
                          )}>
                          Property Managers
                        </Link>
                      </li>
                      {/* <li className={cx(styles.cstm_li, styles.quote_links_li)}>
                        <Link
                          href={HOME_ROUTES.GET_QUOTE}
                          className={cx(
                            styles.fet_a_quote,
                            styles.cstm_a,
                            styles.cmn_quote_links,
                          )}>
                          Get Quote
                        </Link>
                      </li>
                      <li className={cx(styles.cstm_li, styles.quote_links_li)}>
                        <Link
                          href={HOME_ROUTES.BOOK_JOB}
                          className={cx(
                            styles.book_btn,
                            styles.cstm_a,
                            styles.cmn_quote_links,
                          )}>
                          Book Job
                        </Link>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </li>
              {/* <!--  ******************* only for mobile tab view ******************* --> */}
            </ul>
          </div>
          <div className={cx(styles.quote_search, styles.for_desk_open)}>
            <div className={styles.quote_manage}>
              <div className={cx(styles.menu, styles.for_mobile)}>
                <ul className={styles.menu_ul}>
                  <li className={cx(styles.cstm_li, styles.menu_item)}>
                    <Link
                      href={`tel:${CONTACT.PHONE}`}
                      target="_blank"
                      className={styles.menu_link}>
                      <i className={styles.phone_icon}>
                        <img src="/images/header/phone.svg" />{' '}
                      </i>{' '}
                      {CONTACT.PHONE}
                    </Link>
                  </li>
                  <li className={cx(styles.cstm_li, styles.menu_item)}>
                    <Link
                      href={`mailto:${CONTACT.EMAIL}`}
                      target="_blank"
                      className={styles.menu_link}>
                      <i className={styles.email_icon}>
                        {' '}
                        <img src="/images/header/email.svg" />
                      </i>
                      {CONTACT.EMAIL}
                    </Link>
                  </li>
                </ul>
              </div>

              {/* <div className={styles.search_field}>
                <div>
                  <span className={styles.search_btn}>
                    <img src="/images/header/search-btn.svg" />
                  </span>
                </div>

                <div className={styles.search_modal}>
                  <div className={styles.modal_inner_container}>
                    <div className={styles.search_close}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 320 512">
                        <path d="M312 375a24 24 0 0 1-34 34L160 290 41 409a24 24 0 0 1-34-34l119-119L7 136a24 24 0 0 1 34-34l119 120 119-119a24 24 0 0 1 34 34L194 256l118 119z"></path>
                      </svg>
                    </div>
                    <div className={styles.search_form_wrapper}>
                      <form action="#">
                        <div className={styles.input_group}>
                          <input
                            type="text"
                            placeholder="Search"
                            name="property-address"
                            className={styles.search_modal_input}
                            required
                          />
                          <div className={styles.search_group}>
                            <button
                              type="submit"
                              className={cx(
                                styles.button,
                                styles.search_submit,
                              )}>
                              <img src="/images/header/search-btn.svg" />
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div> */}

              <ul className={styles.quote_links}>
                <li className={cx(styles.cstm_li, styles.quote_links_li)}>
                  <Link
                    href={HOME_ROUTES.PROPERTY_MANAGER}
                    className={cx(
                      styles.property_manage,
                      styles.cmn_quote_links,
                      styles.cstm_a,
                    )}>
                    Property Managers
                  </Link>
                </li>
                {/* <li className={cx(styles.cstm_li, styles.quote_links_li)}>
                  <Link
                    href={HOME_ROUTES.GET_QUOTE}
                    className={cx(
                      styles.cstm_a,
                      styles.fet_a_quote,
                      styles.cmn_quote_links,
                    )}>
                    Get Quote
                  </Link>
                </li>
                <li className={cx(styles.cstm_li, styles.quote_links_li)}>
                  <Link
                    href={HOME_ROUTES.BOOK_JOB}
                    className={cx(
                      styles.cstm_a,
                      styles.book_btn,
                      styles.cmn_quote_links,
                    )}>
                    Book Job
                  </Link>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

type DesktopServiceLinkProps = {
  styles: any;
  title: string;
  linkHref: string;
};
const DesktopServiceLink = ({
  styles,
  title,
  linkHref,
}: DesktopServiceLinkProps) => (
  <li className={cx(styles.cstm_li, styles.menu_item)}>
    <Link href={linkHref} className={cx(styles.cstm_a, styles.menus_link)}>
      {' '}
      {title}{' '}
    </Link>
  </li>
);

type DesktopMoreServiceLinkProps = {
  styles: any;
  title: string;
  linkHref: string;
};
const DesktopMoreServiceLink = ({
  styles,
  title,
  linkHref,
}: DesktopMoreServiceLinkProps) => (
  <li className={cx(styles.cstm_li, styles.menu_item)}>
    <Link
      href={linkHref}
      className={cx(styles.cstm_a, styles.menus_link, styles.sub_menu_link)}>
      {' '}
      {title}{' '}
    </Link>
  </li>
);
