export const FLOW_PIPE = {
  LIST: '|| ,',
  ITEM: '::',
};

export const VAT = 0.2;

export const SEO_TITLE =
  'Expert Gas Engineers in London | Heating Engineer London | Qeeper';
export const SEO_DESCRIPTION =
  'Expert Gas Engineers in London offering emergency services, advance plumbing, boiler repair, installation, and more. Trust our team for all your heating needs.';
export const SEO_KEYWORDS =
  'Expert Gas Engineers London, Emergency Gas Engineers London, Advance Plumbing Services London, Heating & Gas Safe Engineer London, Boiler Repair Services London, Heating & Gas Installation London, Plumber London, Gas Engineer London, Heating Engineer London, Boiler Repair London, Boiler Replacement London, Electrician London, EICR London, Landlord Certificates London, Gas Safety Check London';

export const HOME_ROUTES = {
  HOME: '/',
  PROPERTY_MANAGER: '/property-managers',
  GET_QUOTE: '/get-quote',
  BOOK_JOB: '/book-job',
  SERVICES: {
    ELECTRICAL: '/services/electrical',
    PLUMBING: '/services/plumbing',
    HEAT_AND_GAS: '/services/heating-and-gas',
    HANDYMAN: '/services/handyman',
    APPLIANCE_REPAIR: '/services/appliance-repair',
    LANDLORD_CERTIFICATE: '/services/landlord-certificate',
    LOCKSMITH: '/services/locksmith',
    FIRE_RISK_ASSESSMENT: '/services/fire-risk-assessment',
    CARPET_AND_UPHOLSTERY_CLEANING: '/services/carpet-and-upholstery-cleaning',
    DRAINAGE: '/services/drainage',
    WINDOW_DOOR: '/services/window-door',
  },
};

export const CONTACT = {
  EMAIL: 'request@qeeper.co.uk',
  PHONE: '0333 335 5712',
  ADDRESS: 'Qeeper, 167 City Road, London EC1V 1AW',
};

export const BACKED_BY = {
  SFC: 'http://sfccapital.com',
  ANTLER: 'https://www.antler.co/',
};

export const ACCREDITATIONS = {
  SAFE: 'https://www.safecontractor.com/en-gb/',
  GAS_SAFE: 'https://www.gassaferegister.co.uk/',
};
